import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { logout } from 'store/actions/app'
import queryString from 'query-string'

import { callApi } from 'utils/api'

import styles from '../styles/global.module.scss'

import {
	Message,
	Button,
	Menu,
	Icon,
	Popup,
	Image,
	Grid,
	Label,
	Header,
} from 'semantic-ui-react'

import './Header.scss'

const getAvatar = (user) => {
	if (user?.photo && user?.photo.length > 0) {
		return user.photo
	} else {
		const name = user?.entity?.name ?? user?.username
		return `https://ui-avatars.com/api/?name=${name}`
	}
}

const HeaderComponent = (props) => {
	const { setVisible, rest, logout, history } = props

	const [hasAdminRights, setHasAdminRights] = useState(false)
	const [petitions, setPetitions] = useState([])

	let userName = rest.user?.username

	useEffect(() => {
		const nominalListModule = rest.user.modules.find(
			(m) => m.url == 'nominal_list'
		)
		let hasRights = false
		if (nominalListModule) {
			hasRights = nominalListModule.permisitions?.ACCESS
		}
		if (hasRights) {
			componentDidMount()
		}
		setHasAdminRights(hasRights)
	}, [])

	const componentDidMount = async () => {
		let res = await callApi(`/assistance?status.eq=${4}`, {
			method: 'GET',
			headers: {
				Authorization: rest.user.token,
			},
		})
		setPetitions(res.body)
	}

	const notificationsCount = useMemo(() => {
		return petitions?.length ?? 0
	}, [petitions])

	return (
		<Menu
			borderless
			style={{ margin: 0, background: '#3966A9', boxShadow: '2px 1px #000000' }}
			size="tiny"
		>
			<Menu.Item
				className={styles.shrinkOnHover}
				onClick={() => {
					setVisible(true)
				}}
			>
				<Icon name="list" size="large" inverted />
			</Menu.Item>
			<Menu.Item
				onClick={() => {
					history.push('/')
				}}
			>
				<Header className={styles.shrinkOnHover} style={{ color: 'white' }}>
					Sistema De Gestión de Apoyos
					<Header.Subheader style={{ color: 'white' }}>
						V 1.0.5
					</Header.Subheader>
				</Header>
			</Menu.Item>
			<Menu.Menu fitted position="right">
				<Menu.Item position="right" fitted className="NavBar__Item">
					{hasAdminRights && (
						<Popup
							pinned
							trigger={
								<Menu.Item style={{ marginRight: '1vw', padding: '0 0.8em' }}>
									<Icon
										className="NavBar__Outline"
										size="big"
										name="bell"
										fitted
									>
										{notificationsCount > 0 && (
											<Label
												color="red"
												floating
												circular
												style={{
													fontFamily:
														"Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
													fontSize: '0.45em',
												}}
											>
												{notificationsCount}
											</Label>
										)}
									</Icon>
								</Menu.Item>
							}
							content={
								<div>
									{notificationsCount == 0 && (
										<Message
											size="tiny"
											warning
											icon="info"
											header={`No hay notificaciones`}
										/>
									)}
									{petitions.length > 0 && (
										<Message
											size="tiny"
											onClick={() => {
												history.replace({
													pathname: '/assistances',
													search: queryString.stringify({
														filter: petitions.map((e) => e.id).join(','),
													}),
												})
											}}
											warning
											icon="warning"
											header={`${petitions.length} petici${
												petitions.length > 1 ? 'ones' : 'ón'
											} de apoyo`}
											content="Click aquí para ver detalles"
										/>
									)}
								</div>
							}
							position="bottom right"
							on="click"
						/>
					)}
				</Menu.Item>
				<Menu.Item fitted position="right" style={{ marginRight: '2vw' }}>
					<Popup
						pinned
						on="click"
						trigger={
							<Menu.Item className={styles.shrinkOnHover}>
								<Image src={getAvatar(rest.user)} avatar />
							</Menu.Item>
						}
						content={
							<Grid
								divided
								columns="equal"
								className="Header_PopUp"
								style={{ display: 'inline-block' }}
							>
								<Grid.Row>
									<Grid.Column textAlign="middle" verticalAlign="middle">
										<Image
											className="Header_PopUp_Image"
											src={getAvatar(rest.user)}
											avatar
										/>
									</Grid.Column>
									<Grid.Column textAlign="middle" verticalAlign="middle">
										<div className="Header_PopUp_Name">{userName}</div>
										<div className="Header_PopUp_Email">
											{rest.user?.role.name}
										</div>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row>
									<Grid.Column>
										<Button
											className="Header_PopUp_Button"
											basic
											icon
											labelPosition="left"
											onClick={() => {
												logout()
											}}
										>
											<Icon name="sign out" />
											Cerrar sesión
										</Button>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						}
						position="bottom right"
					/>
				</Menu.Item>
			</Menu.Menu>
		</Menu>
	)
}

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user,
	user: state.app.user,
	employees: state.employees,
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)
