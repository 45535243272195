import { useState, useEffect, Fragment } from 'react'
import { Route, Redirect } from 'react-router-dom'
import HeaderRouter from '../components/HeaderRouter'
import HeaderC from '../components/Header'
import ReactDOM from 'react-dom'

import {
	Sidebar,
	Segment,
} from 'semantic-ui-react'
import { connect } from 'react-redux'

const PrivateRoute = (
	{ isAuthenticated = true, component: Component, ...rest },
	props
) => {
	const [visible, setVisible] = useState(false)

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<Fragment>
						<HeaderC
							setVisible={setVisible}
							rest={rest}
                     logout={rest.logout}
							history={props.history}
						/>
						{ReactDOM.createPortal(
							<Sidebar
								animation="push"
								as={Segment}
								inverted
								onHide={() => setVisible(false)}
								vertical
								visible={visible}
							>
								<HeaderRouter history={props.history} />
							</Sidebar>,
							document.getElementById('root')
						)}
						<Component {...props} />
					</Fragment>
				) : (
					<Redirect to="/login" />
				)
			}
		/>
	)
}

const mapDispatchToProps = (dispatch) => ({
})

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.app.user,
	user: state.app.user,
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
