import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Menu, Accordion, Icon, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import AccordionMenu from 'components/AccordionMenu'

import styles from '../styles/global.module.scss'

import _ from 'lodash'

const HeaderRouter = (props) => {
	const { user } = props

	const [activeHeaderIndex, setActiveHeaderIndex] = useState(-1)
	const [activeSubHeaderIndex, setActiveSubHeaderIndex] = useState(-1)

	const handleHeaderClick = (event, title) => {
		event.preventDefault()
		event.stopPropagation()
		const { index } = title
		const activeIndex = activeHeaderIndex
		const newIndex = activeIndex === index ? -1 : index
		setActiveHeaderIndex(newIndex)
	}

	const handleSubHeaderClick = (event, title) => {
		event.preventDefault()
		event.stopPropagation()
		const { index } = title
		const activeIndex = activeSubHeaderIndex
		const newIndex = activeIndex === index ? -1 : index
		setActiveSubHeaderIndex(newIndex)
	}

	const modulesToRender = user?.modules.filter((mod) => {
		const { permisitions } = mod

		return permisitions?.ACCESS
	})

	const headers = modulesToRender.reduce((accu, module) => {
		const x = accu.find((item) => item.module_group == module.module_group)
		if (!x) {
			return accu.concat([module])
		} else {
			return accu
		}
	}, [])

	const subHeaders = _.uniq(
		modulesToRender.reduce((accu, module) => {
			const x = accu.find((item) => item?.sub_group == module?.sub_group)
			if (!x) {
				if (module.sub_group) {
					return accu.concat([
						{
							title: module?.sub_group,
							group: module?.module_group,
							sub_group: module?.sub_group,
						},
					])
				} else {
					return accu
				}
			} else {
				return accu
			}
		}, [])
	)
	const routes = modulesToRender
		.map((m) => {
			if (m.isVisible) {
				return {
					title: m.name,
					to: m.url,
					group: m.module_group,
					sub_group: m.sub_group,
				}
			}
		})
		.filter((mf) => mf != null)

	return (
		<React.Fragment>
			<div>
				<Menu inverted vertical fluid>
					{headers.map((header, index) => (
						<Segment
							key={`segment-${header.module_group}-${index}`}
							inverted
							style={{ margin: '0px', padding: '0px' }}
						>
							<Accordion
								key={`accordion-${header.module_group}-${index}`}
								inverted
								fluid
							>
								<Accordion.Title
									key={`title-${header.module_group}-${index}`}
									style={{ marginBottom: '0px', paddingBottom: '0px' }}
									active={activeHeaderIndex === index}
									index={index}
									onClick={handleHeaderClick}
								>
									<Icon name="dropdown" />
									{header.module_group}
								</Accordion.Title>
								<Accordion.Content
									key={`content-${header.module_group}-${index}`}
									style={{ marginTop: '0px', paddingTop: '0px' }}
									active={activeHeaderIndex === index}
								>
									{subHeaders.map((subHeader, indexH) => {
										if (subHeader.group == header.module_group)
											return (
												<Segment
													key={`segment-${subHeader.title}-${indexH}`}
													inverted
													style={{
														marginTop: '0px',
														marginBottom: '0px',
														marginRight: '0px',
														marginLeft: '15px',
														padding: '0px',
													}}
												>
													<Accordion
														style={{ marginTop: '0px', paddingTop: '0px' }}
														key={`accordion-${subHeader.title}-${indexH}`}
														inverted
														fluid
													>
														<Accordion.Title
															key={`title-${subHeader.title}-${indexH}`}
															style={{ marginBottom: '0px', paddingBottom: '0px' }}
															active={activeSubHeaderIndex === indexH}
															index={indexH}
															onClick={handleSubHeaderClick}
														>
															<Icon name="dropdown" />
															{subHeader.title}
														</Accordion.Title>
														<Accordion.Content
															key={`content-${subHeader.title}-${indexH}`}
															style={{ marginTop: '0px', paddingTop: '0px' }}
															active={activeSubHeaderIndex === indexH}
														>
															<Menu inverted vertical fluid>
																{routes.map((route, indexR) => {
																	if (route.sub_group === subHeader.title)
																		return (
																			<Link
																				key={`link-${indexR}-${route.title}`}
																				to={`/${route.to}`}
																			>
																				<Menu.Item className={styles.growOnHover}>
																					{route.title}
																				</Menu.Item>
																			</Link>
																		)
																})}
															</Menu>
														</Accordion.Content>
													</Accordion>
												</Segment>
											)
									})}
									<Menu inverted vertical fluid>
										{routes.map((route, indexR) => {
											if (route.group == header.module_group && !route.sub_group)
												return (
													<Link key={`link-${indexR}-${route.title}`} to={`/${route.to}`}>
														<Menu.Item className={styles.growOnHover}>
															{route.title}
														</Menu.Item>
													</Link>
												)
										})}
									</Menu>
								</Accordion.Content>
							</Accordion>
						</Segment>
					))}
				</Menu>
			</div>
		</React.Fragment>
	)
}

const mapStateToProps = (state) => ({
	user: state.app.user,
})

export default connect(mapStateToProps)(HeaderRouter)
