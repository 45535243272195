import { createStore, combineReducers, applyMiddleware, compose } from 'redux'

//  Reducers
import appReducer from './reducers/app'
import companyReducer from './reducers/companys'
import userReducer from './reducers/users'
import roleReducer from './reducers/roles'
import citizenReducer from './reducers/citizens'
import managementReducer from './reducers/managements'
import assistanceReducer from './reducers/assistances'
import votingSectionReducer from './reducers/voting_sections'
import nominalListReducer from './reducers/nominal_lists'

//Middleware
import thunk from 'redux-thunk'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
	const store = createStore(
		combineReducers({
			app: appReducer,
			companys: companyReducer,
			users: userReducer,
			roles: roleReducer,
			citizens: citizenReducer,
			managements: managementReducer,
			assistances: assistanceReducer,
			voting_sections: votingSectionReducer,
			nominal_lists: nominalListReducer,
		}),
		composeEnhancers(applyMiddleware(thunk))
	)
	return store
}
