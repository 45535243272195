import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

import PublicRoute from "../components/PublicRoute";
import PrivateRoute from "../components/PrivateRoute";
import { loginWithToken } from "store/actions/app";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

const HomePage = lazy(() => import('./home/HomePage'));
const LoginRoute = lazy(() => import('./login/index'));

const Routers = (props) => {
   const [isTryingToLogin, setIsTryingToLogin] = useState(true)

   const tryToLogin = async () => {
      const token = localStorage.getItem('@token')
      const employee_code = localStorage.getItem('@employee_code')
      if (token) {
        if (employee_code) {
          await props.loginWithToken({ token, employee_code });
        } else {
          await props.loginWithToken({ token });
        }
      }
         
      setIsTryingToLogin(false)
   }
   useEffect(() => {
      tryToLogin()
   }, [])

   if (isTryingToLogin) {
      return <Dimmer active inverted className='padding-0'>
         <Loader inverted>Cargando</Loader>
      </Dimmer>
   }

   const modulesToRender = props.user?.modules.filter(mod => {
      const {permisitions} = mod;

      return permisitions?.ACCESS;
   })

   return (
      <React.Fragment>
         <BrowserRouter>
            <Suspense fallback={
               <Segment className='width-100 height-100 padding-0 border-none margin-0 border-radius-0'>
                  <Dimmer active inverted className='padding-0'>
                     <Loader inverted>Cargando página</Loader>
                  </Dimmer>
               </Segment>
            }>
               <Switch>
                  <PublicRoute path="/login" component={LoginRoute} exact={true} />
                  <PrivateRoute path="/" component={HomePage} exact={true} />
                  {props.isAuthenticated ? ( <>
                     {modulesToRender.map(m => {
                        const ComponentPage = lazy(() => import(`${m.folder}`));

                        if (ComponentPage)
                           return (
                              <PrivateRoute
                                 path={`/${m.url}`}
                                 component={ComponentPage}
                                 title={m.name}
                                 exact={true}
                                 key={m.url}
                              />
                           )
                        else{
                           return null
                        }
                     })}
                  </>)
                  :
                  (
                     <Redirect to="/login" />
                  )
                  }
               </Switch>
            </Suspense>
         </BrowserRouter>
         {/* <NotificationMessage/>
      <ConfirmAction/>
      <InputModal/> */}
      </React.Fragment>
   )
}
const mapDispatchToProps = (dispatch) => ({
   loginWithToken: (parmas) => dispatch(loginWithToken(parmas)),
});

const mapStateToProps = (state) => ({
   user: state.app.user,
   isAuthenticated: !!state.app.user
});

export default connect(mapStateToProps, mapDispatchToProps)(Routers);